

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  overflow: hidden;
}

ul {
  gap: 20px;
  flex-wrap: wrap;
}

.link {
  list-style: none;
  margin: 0;
  padding: 8px 20px;
  margin: 0 5px;
  border-radius: 15px 5px 15px 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #333;
  text-decoration: none;
}

.link:hover {
  background-color: #74c69d;
}

.active {
  background-color: #74c69d;
}

.logo {
  height: 60px;
  width: auto;
}


@media (max-width: 850px) {
  .nav-links {
    display: none;
  }
  .logo {
    margin: auto;
  }
}
