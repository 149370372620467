@font-face {
  font-family: 'NeueHaas';
  src: url('./assets/fonts/NeueHaasDisplayRoman.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'NeueHaas', sans-serif;
  background-color: #f3f3f3;
}

html {
  scroll-behavior: smooth;
}
