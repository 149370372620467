button {
  padding: 12px 24px;
  background-color: #52b788;
  border: none;
  font-size: 16px;
  border-radius: 15px 5px 15px 5px;
  color: #f3f3f3;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #40916c;
}