.services {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 80px 15px;
}

.item-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.item-subtitle {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.item {
  margin-top: 10px;
  font-size: 18px;
}

.services-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.services-item {
  padding: 30px;
  border: 1px solid #999;
  flex: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  background-color: #efefef;
}

.separator {
  background-color: #40916c;
  text-align: center;
  color: #d8f3dc;
  padding: 50px;
  margin-top: 50px;
}

.advise {
  margin-top: 10px;
  color: #333;
}

@media (max-width: 768px) {
  .services-content {
    flex-direction: column; /* Empilha os itens */
  }
  .item {
    font-size: 16px;
  }
}