.contact {
  max-width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 80px 15px;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .contact-container {
    align-items: center;
    flex-direction: column;
  }
}